// App.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Contact() {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate('/after-submit');
    }
    return (
        <section id="contact">
            <div class="container">
                <h1>Contact</h1>
                <div class="block"></div>
                <form>
                    <div class="row">
                        <div class="six columns">
                            <label for="exampleRecipientInput">Name</label>
                            <input class="u-full-width" type="text" />
                        </div>
                        <div class="six columns">
                            <label for="exampleEmailInput">Email</label>
                            <input class="u-full-width" type="email" />
                        </div>
                    </div>
                    <div class="row">
                        <label for="exampleMessage">Message</label>
                        <textarea class="u-full-width"></textarea>
                        <input onClick={handleSubmit} class="button-primary" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        </section>

    );
}

export default Contact;
