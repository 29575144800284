import './App.css';
import React from 'react';
import MainContent from './components/MainContent';
import AfterSubmit from './components/AfterSubmit';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/after-submit" element={<AfterSubmit />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
