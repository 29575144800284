// App.js
import React from 'react';

function Projects() {
    return (
        <section id="team">
            <div class="container">
                <div class="row">
                    <h1>Projects</h1>
                    <div class="block"></div>
                    <p> As I have mentioned above in my introduction, I wish to improve people's lives with my technical skills;
                        therefore, some of my projects focus on benefiting people's lives in a different kind of aspect.</p>
                    <br />
                    <h5> EasyEat</h5>
                    <p> EasyEat is an Android application that my team and I have worked on for my CSE course. It has a similar
                        idea of Yelp, where it would find a restaurant for the user. However, we made it easier for the user that
                        they do not need to search for anything. They only need to swipe left or right like Tinder! </p>


                </div>

                <img src="https://i.imgur.com/IAfVZQS.png" width="200" height="300" hspace="13" alt="" />
                <img src="https://i.imgur.com/4cv2X7P.png" width="200" height="300" hspace="13" alt="" />
                <img src="https://i.imgur.com/N9zwnM1.png" width="200" height="300" hspace="13" alt="" />
                <img src="https://i.imgur.com/IfZT6cU.png" width="200" height="300" hspace="13" alt="" />

                <br />
                <br />
                <br />
                <h5> FoodShop Ecommerce Website</h5>
                <p> I developed an ecommerce website supports filtering, shopping cart, and checkout functionalities.
                </p>

                <img src="https://i.imgur.com/WL7L7QF.png" width="1000" height="600" hspace="13" alt="" />

            </div>
        </section>


    );
}

export default Projects;
