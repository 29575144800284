// MainContent.js
import React from 'react';
import Header from './Header';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import WorkExperience from './WorkExperience';

function MainContent() {
  return (
    <>
      <Header />
      <About />
      <WorkExperience />
      <Projects />
      <Contact />
    </>
  );
}

export default MainContent;
