// src/App.js
import React from 'react';

function About() {
    return (
        <section id="about">
            <div class="container">
                <div class="row">
                    <h1>About</h1>
                    <div class="block"></div>
                    <p> Hello everyone! <br /><br />
                        I'm Xiaoxun Zhu, a Computer Science graduate from UCSD, with a dynamic journey that includes
                        impactful roles at Amazon and General Motors.
                        <br /><br />
                        At Amazon, I worked on the development of ProactiveOutboundProblemSolve, a robust Angular and Spring
                        Boot-driven web application. This application is used for streamlining defect reporting and shipment processing
                        workflows, and it is mainly used in North America, Europe, and Japan.
                        <br /> <br />
                        During my tenure at General Motors, I contributed significantly to an Angular and Spring-based web
                        application. This pivotal tool empowered users to validate and maintain critical specification data,
                        instrumental in the productionization of vehicle orders.
                        <br /><br />
                        I aspire to be a contributive individual, hoping to utilize my technical skills and knowledge to provide better convenience and assistance to society, making a positive impact on people's lives.

                    </p>
                </div>
            </div>

        </section>
    );
}

export default About;
