import React from 'react';
import { useNavigate } from 'react-router-dom';

function AfterSubmit() {
    const navigate = useNavigate();
    const goBackHome = () => {
        navigate('/');
    };
    return (
        <section id="about">
            <div className="container">
                <div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <h1>Thank you for your submission. I will get back to you as soon as possible!</h1>
                        <br></br>
                        <input onClick={goBackHome}
                            style={{ marginLeft: 'auto' }} className="button-primary" type="submit" value="Go BACK" />
                    </div>
                </div>
            </div>
        </section>

    );

}

export default AfterSubmit;