// App.js
import React from 'react';

function WorkExperience() {
  return (
    <section id="team">
      <div class="container">
        <div class="row">
          <h1>Work Experience</h1>
          <div class="block"></div>

          <h5 style={{ color: '#ff9500' }}> Amazon</h5>
          <p> Software Engineer |
            August 2022 - February 2023 | Austin, TX</p>
          <br />
          <p> In my role, I've had the incredible opportunity to contribute to a web application aimed at processing defective shipments—dealing with issues like missing, damaged, and incorrect items. This application is used in fulfillment centers spanning across North America, Europe, and Japan.</p>

          <h5 style={{ color: '#ff9500' }}> General Motors</h5>
          <p> Software Engineer |
            June 2019 - September 2019 | San Diego,CA</p>
          <br />
          <p> I developed an Angular and Spring-based web application with a focus on managing vehicle component configuration. For instance, the application facilitates the mapping of specific tire specifications to corresponding vehicle models. Its primary purpose is to validate and ensure the readiness of a vehicle for production.

          </p>

          <h5 style={{ color: '#ff9500' }}> Skinalytics, Inc.</h5>
          <p> Software Engineer Intern | Backend Team
            June 2019 - September 2019 | San Diego,CA</p>
          <br />
          <p> During the summer of 2019, I worked at a start-up that we developed an Android application to help consumers
            to select skin care products in a personalized way that fits the best for their skin types. I set up the AWS
            DynamoDB and implemented user data collection features to collect user's skin types and the skin products the
            customers prefer. I also implemented a login and sign up function for the Android application using AWS
            Cognito.</p>
        </div>


        <div class="row">

          <img src="https://i.imgur.com/UUuy5hT.png" width="200" height="300" hspace="13" alt="" />
          <img src="https://i.imgur.com/BkbjKfj.png" width="200" height="300" hspace="13" alt="" />
          <img src="https://i.imgur.com/C0DaDED.png" width="200" height="300" hspace="13" alt="" />
          <img src="https://i.imgur.com/wbcoq9q.jpg" width="200" height="300" hspace="13" alt="" />
        </div>
      </div>
    </section>

  );
}

export default WorkExperience;
