// App.js
import React from 'react';

function Header() {
  return (
    <div>
          <header id="header">
    <div class="main_nav">
      <div class="container">
        <div class="mobile-toggle"> <span></span> <span></span> <span></span> </div>
        <nav>
          <ul>
            <li><a class="smoothscroll" href="#header">Home</a></li>
            <li><a class="smoothscroll" href="#about">About</a></li>
            <li><a class="smoothscroll" href="#skills">Services</a></li>
            <li><a class="smoothscroll" href="#portfolio">Work</a></li>
            <li><a class="smoothscroll" href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="title">

      <h1> Welcome to</h1>
      <h2 class="heading">XIAOXUN ZHU'S PERSONAL WEBSITE</h2>
      <a class="smoothscroll" href="#about">
        <div class="mouse">
          <div class="wheel"></div>
        </div>
      </a>
    </div>
    <a class="smoothscroll" href="#about">
      <div class="scroll-down"></div>
    </a>
  </header>

    </div>
  );
}

export default Header;
